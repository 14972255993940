(function () {
    angular.module('informaApp')
        .service('BaseApiService', BaseApiService);

    function BaseApiService($http, ConstantsSvc) {
        return {
            get(url, query = null, options = null) {
                const defaultOptions = {method: 'GET'};

                const newUrl = query
                    ? `${url}?${objectToQuery(query, ConstantsSvc)}`
                    : url;

                return this.sendRequest(newUrl, _.merge({}, defaultOptions, options));
            },

            put(url, data, options = null) {
                const defaultOptions = {method: 'PUT', data};

                return this.sendRequest(url, _.merge(defaultOptions, options));
            },

            post(url, data, options = null) {
                const defaultOptions = {method: 'POST', data};

                return this.sendRequest(url, _.merge(defaultOptions, options));
            },

            delete(url, data, options = null) {
                const defaultOptions = {method: 'DELETE', data};

                return this.sendRequest(url, _.merge(defaultOptions, options));
            },

            sendRequest(url, options = {}) {
                const apiUrl = ConstantsSvc.API.URL + url;
                const requestOptions = angular.merge({}, options);
            
                // Logic to retrieve the JWT token from the cookie
                const cookies = document.cookie.split('; ');
                for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i];
                if (cookie.indexOf('token=') === 0) {
                    requestOptions.headers = requestOptions.headers || {};
                    requestOptions.headers['Authorization'] = 'Bearer ' + cookie.split('=')[1];
                    break;
                    }
                }
                requestOptions.url = apiUrl;
                return $http(requestOptions);
            }

        }
    }

    function objectToQuery(source, ConstantsSvc) {
        const queryParams = [];

        for (let propertyName in source)
            if (source.hasOwnProperty(propertyName)) {
                const value = getQueryParameterValue(source[propertyName], ConstantsSvc);

                queryParams.push(encodeURIComponent(propertyName) + '=' + encodeURIComponent(value));
            }

        return queryParams.join('&');
    }

    function getQueryParameterValue(value, ConstantsSvc) {
        return value instanceof Date ? convertDateToString(value, ConstantsSvc) : value;
    }

    function convertDateToString(date, ConstantsSvc) {
        return moment(date).format(ConstantsSvc.Dates.queryFormat);
    }
})();